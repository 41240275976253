$color-dark-blue: #264D59;
$color-green: #ff6402;
$color-yellow: #FFF8DC;
$color-orange: #a5c1c3;
$color-red: #D46C4E;

.app {
  background-color: $color-orange;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.font {
  color: $color-green;
  font-family: 'Roboto Slab', serif;
}

.wrapper {
  padding: 20px;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
  width: 70%; 
  margin-top: 20vh;
}

h2 {
  color: $color-green;
  font-family: 'Sanchez', serif;
}

.home {
  .selector {
    margin-top: 20rem;
  }
}

.splitScreen {
  display: flex;
  flex-direction: row;
}

.leftPane {
  width: 70%;
  margin-right: 20px;
}

.rightPane {
  width: 50%;
  height: 500px;
  margin-left: 10px;
}

.map-container {
  height: 100%;
  width: 100%;
}

.circle
{
  border-radius: 50% !important;
  width: 50% !important;
  height: 50% !important;
}